import React, { useEffect } from "react"
import MindBodyWidget from "../components/mindbody"
import TeamImg from "../images/team.jpeg"
import Layout from '../components/layout-default'

export default () => {

  const scriptID = 'mb-script-01'

  useEffect(() => {
    const timer = setInterval(() => {
      var mbScript = (typeof document !== `undefined`  ? document.querySelector(`[data-link-class=healcode-contract-text-link] .healcode-link`) : undefined)
      console.log('trying..')
      if (mbScript) {
        clearInterval(timer)
        const wrapper = (typeof document !== `undefined`  ? document.querySelector('.contract-wrapper') : undefined)
        wrapper.classList.add('is-active')
      }
    }, 500)

  }) 

  return (
    <Layout
      title="Bachata &amp; Salsa Class Schedule | RF Dance"
      description="Sign up for a bachata or salsa class with RF Dance online." 
      pathname="classes"
      img={TeamImg}
      noHero="no-hero">
      <div className="container" style={({'minHeight':`50vh`})}>
        <p style={({
          'position': `absolute`,
          'margin': `4em auto`,
          'zIndex': -1,
          'left': `50%`,
          'marginLeft': `-70px`
        })}>Loading classes...</p>
        <div className="contract-wrapper">
          <h3 className='contract-header'>Become an RF Member and save.</h3>
          <p className='contract-body'>Get instant access to multiple classes each week, a workshop, and exclusive training by becoming a monthly member. Save time at each class and get a great deal on lessons. <strong>$75 monthly, Limited time offer!</strong></p>
          <p className='contract-body'>This monthly unlimited membership includes Mondays Bachata, Tuesdays Bachata, Wednesdays Salsa, Thursdays Cumbia, Sunday Trainings, Monthly Workshop, and $75 Private Lessons.</p>
          <MindBodyWidget
            id={scriptID}
            type="contract-link"
            version="0.2"
            linkClass="healcode-contract-text-link"
            siteId="62245"
            mbSiteId="222416"
            innerHtml="Become an RF Member"
            serviceId="106" />
        </div>
        <MindBodyWidget
          id="mb-script-02" 
          type="schedules" 
          widgetPartner="object" 
          widgetId="d48507309b0" 
          widgetVersion="1" />
      </div>
      <style jsx="jsx">{`
        .contract-wrapper {
          text-align: center;
          padding: 3em 0;
          background-color: #fff;
          display: none;
        }
        .contract-wrapper a {
          background-color: #209cee;
          padding: 1em 2em;
          border-radius: 27px;
          color: #fff;
          border: 1px solid #209cee;
        }
        .contract-wrapper a:hover {
          background-color: #fff;
          color: #209cee;
        }
        .contract-header, .contract-body {
          display: none;
        }
        .contract-wrapper.is-active {
          display: block;
        }
        .contract-wrapper.is-active .contract-header,
        .contract-wrapper.is-active .contract-body {
          display: block;
        }
        .contract-body {
          max-width: 80%;
          margin: 0 auto 3em;
        }
      `}</style>
    </Layout>
)}